// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconGroup(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Group";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsx("g", {
                                      children: JsxRuntime.jsxs("g", {
                                            children: [
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "60",
                                                    width: "60",
                                                    x: "0",
                                                    y: "0"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "25",
                                                    width: "25",
                                                    rx: "2",
                                                    stroke: "#98A0B0",
                                                    strokeWidth: "2",
                                                    x: "1",
                                                    y: "1"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Combined-Shape",
                                                    d: "M35,-1.0658141e-14 L58,-1.0658141e-14 C59.1045695,-1.08610472e-14 60,0.8954305 60,2 L60,25 C60,26.1045695 59.1045695,27 58,27 L35,27 C33.8954305,27 33,26.1045695 33,25 L33,2 C33,0.8954305 33.8954305,-1.04552349e-14 35,-1.0658141e-14 Z M40,6 C39.4477153,6 39,6.44771525 39,7 L39,20 C39,20.5522847 39.4477153,21 40,21 L53,21 C53.5522847,21 54,20.5522847 54,20 L54,7 C54,6.44771525 53.5522847,6 53,6 L40,6 Z",
                                                    fill: "#18C3BB",
                                                    stroke: Icon.mapColor(props.color)
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle-Copy-6",
                                                    height: "25",
                                                    width: "25",
                                                    rx: "2",
                                                    stroke: "#98A0B0",
                                                    strokeWidth: "2",
                                                    x: "1",
                                                    y: "34"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle-Copy-8",
                                                    height: "25",
                                                    width: "25",
                                                    rx: "2",
                                                    stroke: "#98A0B0",
                                                    strokeWidth: "2",
                                                    x: "34",
                                                    y: "34"
                                                  })
                                            ],
                                            id: "Group"
                                          }),
                                      id: "Icon-/-Big-/-Marketplace",
                                      transform: "translate(540.000000, 640.000000)"
                                    }),
                                id: "About-us",
                                fillRule: "nonzero",
                                transform: "translate(-540.000000, -640.000000)"
                              }),
                          id: "Site",
                          fill: "none",
                          fillRule: "evenodd",
                          stroke: "none",
                          strokeWidth: "1"
                        }),
                    height: "100%",
                    width: "100%",
                    version: "1.1",
                    viewBox: "0 0 60 60",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconGroup;

export {
  make ,
}
/* Icon Not a pure module */
