// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Button from "../Button/Button.res.js";
import * as BasicHeroScss from "./BasicHero.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = BasicHeroScss;

function aboutContainerClassName(color) {
  var tmp;
  switch (color) {
    case "Blue" :
        tmp = css.blueOverlay;
        break;
    case "Teal" :
        tmp = css.tealOverlay;
        break;
    case "Purple" :
        tmp = css.purpleOverlay;
        break;
    case "None" :
        tmp = css.noOverlay;
        break;
    
  }
  return Cx.cx([
              css.aboutContainer,
              tmp
            ]);
}

function BasicHero(props) {
  var __buttonClick = props.buttonClick;
  var __buttonLink = props.buttonLink;
  var __buttonText = props.buttonText;
  var __appendSup = props.appendSup;
  var __smallDescription = props.smallDescription;
  var smallDescription = __smallDescription !== undefined ? __smallDescription : "";
  var appendSup = __appendSup !== undefined ? __appendSup : false;
  var buttonText = __buttonText !== undefined ? __buttonText : "";
  var buttonLink = __buttonLink !== undefined ? __buttonLink : "";
  var buttonClick = __buttonClick !== undefined ? __buttonClick : (function (prim) {
        
      });
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsxs("h1", {
                                        children: [
                                          props.title,
                                          appendSup ? JsxRuntime.jsx("sup", {
                                                  children: "SM"
                                                }) : null
                                        ]
                                      }),
                                  JsxRuntime.jsx("h2", {
                                        children: props.subtitle
                                      }),
                                  smallDescription !== "" ? JsxRuntime.jsx("h2", {
                                          children: smallDescription
                                        }) : null,
                                  buttonText !== "" && buttonLink !== "" ? JsxRuntime.jsx(Button.AsLink.make, {
                                          href: buttonLink,
                                          size: "MD",
                                          color: "Primary",
                                          className: css.basicButton,
                                          children: buttonText
                                        }) : null,
                                  buttonText !== "" && buttonLink === "" ? JsxRuntime.jsx(Button.AsNativeLink.make, {
                                          href: "javascript:;",
                                          size: "MD",
                                          color: "Primary",
                                          className: css.basicButton,
                                          onClick: buttonClick,
                                          children: buttonText
                                        }) : null
                                ],
                                className: css.aboutTextContainer
                              }),
                          className: css.container
                        }),
                    className: aboutContainerClassName(props.color)
                  }),
              className: css.basicHeroContainer,
              style: {
                backgroundImage: "url(" + (props.backgroundImage + ")")
              }
            });
}

var make = BasicHero;

export {
  css ,
  aboutContainerClassName ,
  make ,
}
/* css Not a pure module */
