// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconResource(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Resource";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsx("g", {
                                      children: JsxRuntime.jsxs("g", {
                                            children: [
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "60",
                                                    width: "60",
                                                    x: "0",
                                                    y: "0"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M9,3 L9,57 L51,57 L51,7.22561358 L46.8556482,3 L9,3 Z M9,1.5816876e-17 L46.8556482,-3.66373598e-15 C47.6613951,-3.81174929e-15 48.433272,0.324120453 48.997463,0.899373999 L53.1418147,5.12498758 C53.6918719,5.68583017 54,6.44005182 54,7.22561358 L54,57 C54,58.6568542 52.6568542,60 51,60 L9,60 C7.34314575,60 6,58.6568542 6,57 L6,3 C6,1.34314575 7.34314575,3.04359188e-16 9,1.5816876e-17 Z",
                                                    fill: "#98A0B0",
                                                    stroke: Icon.mapColor(props.color)
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "6",
                                                    width: "24",
                                                    fill: "#18C3BB",
                                                    rx: "1",
                                                    x: "18",
                                                    y: "12"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "3",
                                                    width: "24",
                                                    fill: "#18C3BB",
                                                    rx: "1",
                                                    x: "18",
                                                    y: "21"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "3",
                                                    width: "24",
                                                    fill: "#18C3BB",
                                                    rx: "1",
                                                    x: "18",
                                                    y: "27"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "3",
                                                    width: "24",
                                                    fill: "#18C3BB",
                                                    rx: "1",
                                                    x: "18",
                                                    y: "33"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "3",
                                                    width: "24",
                                                    fill: "#18C3BB",
                                                    rx: "1",
                                                    x: "18",
                                                    y: "39"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "3",
                                                    width: "15",
                                                    fill: "#18C3BB",
                                                    rx: "1",
                                                    x: "18",
                                                    y: "45"
                                                  })
                                            ],
                                            id: "Group-16"
                                          }),
                                      id: "Icon-/-Big-/-Resources",
                                      transform: "translate(840.000000, 640.000000)"
                                    }),
                                id: "About-us",
                                fillRule: "nonzero",
                                transform: "translate(-846.000000, -640.000000)"
                              }),
                          id: "Site",
                          fill: "none",
                          fillRule: "evenodd",
                          stroke: "none",
                          strokeWidth: "1"
                        }),
                    height: "100%",
                    width: "100%",
                    version: "1.1",
                    viewBox: "0 0 48 60",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconResource;

export {
  make ,
}
/* Icon Not a pure module */
