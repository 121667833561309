// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconProvider(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Provider";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsx("g", {
                                      children: JsxRuntime.jsxs("g", {
                                            children: [
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "16",
                                                    width: "58",
                                                    rx: "2",
                                                    stroke: "#98A0B0",
                                                    strokeWidth: "2",
                                                    x: "1",
                                                    y: "1"
                                                  }),
                                              JsxRuntime.jsx("circle", {
                                                    id: "Oval",
                                                    cx: "9",
                                                    cy: "9",
                                                    fill: "#18C3BB",
                                                    r: "3",
                                                    stroke: Icon.mapColor(props.color)
                                                  }),
                                              JsxRuntime.jsx("circle", {
                                                    id: "Oval",
                                                    cx: "18",
                                                    cy: "9",
                                                    fill: "#18C3BB",
                                                    r: "3"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle-Copy-16",
                                                    height: "16",
                                                    width: "58",
                                                    rx: "2",
                                                    stroke: "#98A0B0",
                                                    strokeWidth: "2",
                                                    x: "1",
                                                    y: "22"
                                                  }),
                                              JsxRuntime.jsx("circle", {
                                                    id: "Oval",
                                                    cx: "9",
                                                    cy: "30",
                                                    fill: "#18C3BB",
                                                    r: "3"
                                                  }),
                                              JsxRuntime.jsx("circle", {
                                                    id: "Oval",
                                                    cx: "18",
                                                    cy: "30",
                                                    fill: "#18C3BB",
                                                    r: "3"
                                                  }),
                                              JsxRuntime.jsx("circle", {
                                                    id: "Oval",
                                                    cx: "9",
                                                    cy: "51",
                                                    fill: "#18C3BB",
                                                    r: "3"
                                                  }),
                                              JsxRuntime.jsx("circle", {
                                                    id: "Oval",
                                                    cx: "18",
                                                    cy: "51",
                                                    fill: "#18C3BB",
                                                    r: "3"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle-Copy-17",
                                                    height: "16",
                                                    width: "58",
                                                    rx: "2",
                                                    stroke: "#98A0B0",
                                                    strokeWidth: "2",
                                                    x: "1",
                                                    y: "43"
                                                  })
                                            ],
                                            id: "Group-5"
                                          }),
                                      id: "Icon-/-Big-/-Provider",
                                      transform: "translate(690.000000, 2471.000000)"
                                    }),
                                id: "About-us",
                                fillRule: "nonzero",
                                transform: "translate(-690.000000, -2471.000000)"
                              }),
                          id: "Site",
                          fill: "none",
                          fillRule: "evenodd",
                          stroke: "none",
                          strokeWidth: "1"
                        }),
                    height: "100%",
                    width: "100%",
                    version: "1.1",
                    viewBox: "0 0 60 60",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconProvider;

export {
  make ,
}
/* Icon Not a pure module */
