// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconValue(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Value";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsxs("g", {
                                      children: [
                                        JsxRuntime.jsx("polygon", {
                                              id: "Rectangle",
                                              fill: "#933CDD",
                                              points: "60 33 70 33 49 57"
                                            }),
                                        JsxRuntime.jsx("polygon", {
                                              id: "Rectangle",
                                              fill: "#933CDD",
                                              points: "52 5 62 5 72 26 60 26",
                                              stroke: Icon.mapColor(props.color)
                                            }),
                                        JsxRuntime.jsx("path", {
                                              id: "Rectangle",
                                              d: "M15.927051,1.5 L1.80129428,29.7515134 L40,71.7700897 L78.1987057,29.7515134 L64.072949,1.5 L15.927051,1.5 Z",
                                              stroke: "#B791D8",
                                              strokeWidth: "3"
                                            }),
                                        JsxRuntime.jsx("path", {
                                              id: "Line-2",
                                              d: "M57,29 L47,2",
                                              stroke: "#B791D8",
                                              strokeLinecap: "square",
                                              strokeWidth: "3"
                                            }),
                                        JsxRuntime.jsx("path", {
                                              id: "Line-2",
                                              d: "M40,70 L23,29",
                                              stroke: "#B791D8",
                                              strokeLinecap: "square",
                                              strokeWidth: "3"
                                            }),
                                        JsxRuntime.jsx("path", {
                                              id: "Line-2",
                                              d: "M23,29 L33,2",
                                              stroke: "#B791D8",
                                              strokeLinecap: "square",
                                              strokeWidth: "3"
                                            }),
                                        JsxRuntime.jsx("path", {
                                              id: "Line-2",
                                              d: "M40,70 L57,29",
                                              stroke: "#B791D8",
                                              strokeLinecap: "square",
                                              strokeWidth: "3"
                                            }),
                                        JsxRuntime.jsx("path", {
                                              id: "Line-3",
                                              d: "M3.5,29.5 L76.5,29.5",
                                              stroke: "#B791D8",
                                              strokeLinecap: "square",
                                              strokeWidth: "3"
                                            })
                                      ],
                                      id: "Value",
                                      transform: "translate(1080.000000, 2019.000000)"
                                    }),
                                id: "About-us",
                                fillRule: "nonzero",
                                transform: "translate(-1080.000000, -2019.000000)"
                              }),
                          id: "Site",
                          fill: "none",
                          fillRule: "evenodd",
                          stroke: "none",
                          strokeWidth: "1"
                        }),
                    height: "100%",
                    width: "100%",
                    version: "1.1",
                    viewBox: "0 0 80 74",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconValue;

export {
  make ,
}
/* Icon Not a pure module */
