// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconAd(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Agent";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsx("g", {
                                      children: JsxRuntime.jsxs("g", {
                                            children: [
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "58",
                                                    width: "58",
                                                    rx: "2",
                                                    stroke: "#98A0B0",
                                                    strokeWidth: "2",
                                                    x: "1",
                                                    y: "1"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M13,12 L47,12 C47.5522847,12 48,12.4477153 48,13 L48,17 C48,17.5522847 47.5522847,18 47,18 L13,18 C12.4477153,18 12,17.5522847 12,17 L12,13 C12,12.4477153 12.4477153,12 13,12 Z",
                                                    fill: "#98A0B0",
                                                    stroke: Icon.mapColor(props.color)
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M13,21 L47,21 C47.5522847,21 48,21.4477153 48,22 L48,26 C48,26.5522847 47.5522847,27 47,27 L13,27 C12.4477153,27 12,26.5522847 12,26 L12,22 C12,21.4477153 12.4477153,21 13,21 Z",
                                                    fill: "#98A0B0"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M13,30 L38,30 C38.5522847,30 39,30.4477153 39,31 L39,35 C39,35.5522847 38.5522847,36 38,36 L13,36 C12.4477153,36 12,35.5522847 12,35 L12,31 C12,30.4477153 12.4477153,30 13,30 Z",
                                                    fill: "#98A0B0"
                                                  }),
                                              JsxRuntime.jsx("rect", {
                                                    id: "Rectangle",
                                                    height: "6",
                                                    width: "18",
                                                    fill: "#18C3BB",
                                                    rx: "1",
                                                    x: "12",
                                                    y: "42"
                                                  })
                                            ],
                                            id: "Group-5"
                                          }),
                                      id: "Icon-/-Big-/-Advertising",
                                      transform: "translate(1090.000000, 2471.000000)"
                                    }),
                                id: "About-us",
                                fillRule: "nonzero",
                                transform: "translate(-1090.000000, -2471.000000)"
                              }),
                          id: "Site",
                          fill: "none",
                          fillRule: "evenodd",
                          stroke: "none",
                          strokeWidth: "1"
                        }),
                    height: "100%",
                    width: "100%",
                    version: "1.1",
                    viewBox: "0 0 60 60",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconAd;

export {
  make ,
}
/* Icon Not a pure module */
