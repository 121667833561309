// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconDirectory(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Directory";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsx("g", {
                                      children: JsxRuntime.jsxs("g", {
                                            children: [
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M12,3 L12,10.5 L49.5,10.5 L49.5,48 L57,48 L57,3 L12,3 Z M12,-1.0658141e-13 L57,-1.0658141e-13 C58.6568542,-1.0688577e-13 60,1.34314575 60,3 L60,48 C60,49.6568542 58.6568542,51 57,51 L49.5,51 L49.5,10.5 L9,10.5 L9,3 C9,1.34314575 10.3431458,-1.06277051e-13 12,-1.0658141e-13 Z",
                                                    fill: "#98A0B0",
                                                    stroke: Icon.mapColor(props.color)
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Rectangle",
                                                    d: "M3,12 L3,57 L48,57 L48,12 L3,12 Z M3,9 L48,9 C49.6568542,9 51,10.3431458 51,12 L51,57 C51,58.6568542 49.6568542,60 48,60 L3,60 C1.34314575,60 2.02906125e-16,58.6568542 0,57 L0,12 C-2.02906125e-16,10.3431458 1.34314575,9 3,9 Z",
                                                    fill: "#98A0B0"
                                                  }),
                                              JsxRuntime.jsx("path", {
                                                    id: "Combined-Shape",
                                                    d: "M13,21 L38,21 C38.5522847,21 39,21.4477153 39,22 L39,47 C39,47.5522847 38.5522847,48 38,48 L13,48 C12.4477153,48 12,47.5522847 12,47 L12,22 C12,21.4477153 12.4477153,21 13,21 Z M19,27 C18.4477153,27 18,27.4477153 18,28 L18,29 C18,29.5522847 18.4477153,30 19,30 L32,30 C32.5522847,30 33,29.5522847 33,29 L33,28 C33,27.4477153 32.5522847,27 32,27 L19,27 Z M19,33 C18.4477153,33 18,33.4477153 18,34 L18,35 C18,35.5522847 18.4477153,36 19,36 L32,36 C32.5522847,36 33,35.5522847 33,35 L33,34 C33,33.4477153 32.5522847,33 32,33 L19,33 Z M19,39 C18.4477153,39 18,39.4477153 18,40 L18,41 C18,41.5522847 18.4477153,42 19,42 L26,42 C26.5522847,42 27,41.5522847 27,41 L27,40 C27,39.4477153 26.5522847,39 26,39 L19,39 Z",
                                                    fill: "#18C3BB"
                                                  })
                                            ],
                                            id: "Group-4"
                                          }),
                                      id: "Icon-/-Big-/-Directory",
                                      transform: "translate(240.000000, 640.000000)"
                                    }),
                                id: "About-us",
                                fillRule: "nonzero",
                                transform: "translate(-240.000000, -640.000000)"
                              }),
                          id: "Site",
                          fill: "none",
                          fillRule: "evenodd",
                          stroke: "none",
                          strokeWidth: "1"
                        }),
                    height: "100%",
                    width: "100%",
                    version: "1.1",
                    viewBox: "0 0 60 60",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconDirectory;

export {
  make ,
}
/* Icon Not a pure module */
