// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as IconBoxScss from "./IconBox.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = IconBoxScss;

function iconContainerClassName(align) {
  var tmp;
  switch (align) {
    case "Left" :
        tmp = css.leftAlign;
        break;
    case "Center" :
        tmp = css.centerAlign;
        break;
    case "Right" :
        tmp = css.rightAlign;
        break;
    
  }
  return Cx.cx([
              css.iconContainer,
              tmp
            ]);
}

function IconBox(props) {
  var __linkTitle = props.linkTitle;
  var __link = props.link;
  var icon = props.icon;
  var link = __link !== undefined ? __link : "";
  var linkTitle = __linkTitle !== undefined ? __linkTitle : "";
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      icon !== undefined ? JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(icon.make, {
                                    size: "XXL",
                                    color: "Teal"
                                  }),
                              className: css.iconContainer
                            }) : null,
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("h3", {
                                    children: props.title
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: props.description,
                                    className: css.description
                                  }),
                              linkTitle !== "" ? JsxRuntime.jsx("a", {
                                      children: linkTitle,
                                      className: css.link,
                                      href: link
                                    }) : null
                            ],
                            className: css.textContainer
                          })
                    ],
                    className: iconContainerClassName(props.align)
                  }),
              className: css.iconContainer
            });
}

var make = IconBox;

export {
  css ,
  iconContainerClassName ,
  make ,
}
/* css Not a pure module */
