// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PartnerScss from "./Partner.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = PartnerScss;

function Partner(props) {
  var __appendSup = props.appendSup;
  var appendSup = __appendSup !== undefined ? __appendSup : false;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("img", {
                                  src: props.imageUrl
                                }),
                            className: css.bitmap
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: props.title,
                                    className: css.title
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      "Datacenters.com",
                                      appendSup ? JsxRuntime.jsx("sup", {
                                              children: "SM"
                                            }) : null,
                                      props.subtitle
                                    ],
                                    className: css.subtitle
                                  }),
                              JsxRuntime.jsx("a", {
                                    children: "Show more",
                                    className: css.link,
                                    href: props.link
                                  })
                            ],
                            className: css.details
                          })
                    ],
                    className: css.rectangle
                  }),
              className: css.partnerContainer
            });
}

var make = Partner;

export {
  css ,
  make ,
}
/* css Not a pure module */
