// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as IconAd from "../../styleguide/icons/IconAd.res.js";
import * as IconBox from "../../styleguide/components/IconBox/IconBox.res.js";
import * as Partner from "../../styleguide/components/Partner/Partner.res.js";
import * as BasicHero from "../../styleguide/components/BasicHero/BasicHero.res.js";
import * as IconGroup from "../../styleguide/icons/IconGroup.res.js";
import * as IconValue from "../../styleguide/icons/IconValue.res.js";
import * as Cloudinary from "../../libs/Cloudinary.res.js";
import * as IconVision from "../../styleguide/icons/IconVision.res.js";
import * as ProgramBox from "../../styleguide/components/ProgramBox/ProgramBox.res.js";
import * as IconMission from "../../styleguide/icons/IconMission.res.js";
import * as Testimonial from "../../styleguide/components/Testimonial/Testimonial.res.js";
import ReactSlick from "react-slick";
import * as AboutScss from "./About.scss";
import * as IconProvider from "../../styleguide/icons/IconProvider.res.js";
import * as IconResource from "../../styleguide/icons/IconResource.res.js";
import * as IconDirectory from "../../styleguide/icons/IconDirectory.res.js";
import * as Routes_Location from "../../routes/common/Routes_Location.res.js";
import * as Routes_Resource from "../../routes/common/Routes_Resource.res.js";
import * as AboutSectionHead from "./AboutSectionHead.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../routes/common/Routes_Marketplace.res.js";

var css = AboutScss;

var aboutImage1 = Cloudinary.imageUrl("f_auto/jlj1kawz7e9ecaxz9mnx");

var aboutImage2 = Cloudinary.imageUrl("f_auto/f2cvgn3eouvla6ruaqrv");

var aboutImage3 = Cloudinary.imageUrl("f_auto/gyyik0cvmax5ncwojf0q");

var aboutImage4 = Cloudinary.imageUrl("f_auto/a1lqahoc0oupak3x9jkb");

var aboutImage5 = Cloudinary.imageUrl("f_auto/xp2d2ejr83vvz0cetm8n");

var aboutImage6 = Cloudinary.imageUrl("f_auto/lf53pphcokrw4wom3hws");

var gcgImage = Cloudinary.imageUrl("f_auto/dngxgs7raj9r86x96ano");

var gcfImage = Cloudinary.imageUrl("cxhji2b8epyfdtqjxwfg");

function About$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(BasicHero.make, {
                      title: "Who We Are? Datacenters.Com",
                      subtitle: "Datacenters.com is a technology marketplace and aggregator. We offer data center colocation, cloud, connectivity, hardware, software, and managed services from providers worldwide. Visitors can quickly search data center listings, compare provider offerings and purchase technology solutions.",
                      backgroundImage: Cloudinary.imageUrl("f_auto/static/about_us_header.jpg"),
                      color: "None",
                      smallDescription: "We are privately held and headquarters In Englewood, Colorado, United States.",
                      appendSup: true
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(IconBox.make, {
                                                        title: "Directory",
                                                        description: "Research data centers, cloud nodes, Internet exchanges (IX), disaster recovery (DR) sites globally.",
                                                        icon: IconDirectory,
                                                        align: "Center",
                                                        link: Routes_Location.index,
                                                        linkTitle: "Search Locations"
                                                      }),
                                                  className: css.column3
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(IconBox.make, {
                                                        title: "Marketplace",
                                                        description: "Click-to-reserve colocation, cloud, internet and network connectivity, IT services, hardware, and software.",
                                                        icon: IconGroup,
                                                        align: "Center",
                                                        link: Routes_Marketplace.colocation,
                                                        linkTitle: "View Marketplace"
                                                      }),
                                                  className: css.column3
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(IconBox.make, {
                                                        title: "Resource",
                                                        description: "Download data center checklists, plans, case studies, white papers, and reports from top providers.",
                                                        icon: IconResource,
                                                        align: "Center",
                                                        link: Routes_Resource.resourcesIndex,
                                                        linkTitle: "Download Resources"
                                                      }),
                                                  className: css.column3
                                                })
                                          ],
                                          className: Cx.cx([
                                                css.features,
                                                css.marginTop0
                                              ])
                                        }),
                                    className: css.row
                                  }),
                              JsxRuntime.jsx("hr", {
                                    className: css.divider
                                  }),
                              JsxRuntime.jsx(AboutSectionHead.make, {
                                    title: "Our Partnerships",
                                    subtitle: "It's all about the people. We aim to deliver the human touch."
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Partner.make, {
                                                  title: "Global Consulting Group",
                                                  subtitle: " partnered with Global Consulting Group. Inc. to provide professional services and consulting. GCG is a solution-based, technology...",
                                                  imageUrl: gcgImage,
                                                  link: "https://www.gcgcom.com/",
                                                  appendSup: true
                                                }),
                                            JsxRuntime.jsx(Partner.make, {
                                                  title: "Global Care Foundation",
                                                  subtitle: " partnered with Global Care Foundation (GCF), a not-profit 501(c)(3) organization focused on giving back...",
                                                  imageUrl: gcfImage,
                                                  link: "http://globalcarefoundation.org/",
                                                  appendSup: true
                                                })
                                          ],
                                          className: css.partner
                                        }),
                                    className: css.features
                                  })
                            ],
                            className: Cx.cx([
                                  css.container,
                                  css.containerGutter
                                ])
                          }),
                      className: css.overview
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsxs("h2", {
                                                  children: [
                                                    "The Datacenters.com",
                                                    JsxRuntime.jsx("sup", {
                                                          children: "SM "
                                                        }),
                                                    " Story"
                                                  ]
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: "From data center directory to technology marketplace.",
                                                  className: css.subtitle
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    "Datacenters.com",
                                                    JsxRuntime.jsx("sup", {
                                                          children: "SM "
                                                        }),
                                                    " was created in 2011 after we scoured the Internet to fin information about data center locations, facilities and providers. There wasn't a website that contained a centralized list of data centers addresses, descriptions, pictures and video tours. In order to find this information. It took searching multiple websites, articles and press releases. From the beginning, it was our mission to create directory that made it easy to search, find and compare data centers globally."
                                                  ],
                                                  className: css.description
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    "What started as a data center map and directory has evolved into a platform that enables users to ",
                                                    JsxRuntime.jsx("a", {
                                                          children: "search data center locations ",
                                                          href: "/locations"
                                                        }),
                                                    "and ",
                                                    JsxRuntime.jsx("a", {
                                                          children: "provider profiles",
                                                          href: "/providers"
                                                        }),
                                                    ", ",
                                                    JsxRuntime.jsx("a", {
                                                          children: "download helpful resource",
                                                          href: "/resources"
                                                        }),
                                                    ", and purchase products and services online through out proprietary ",
                                                    JsxRuntime.jsx("a", {
                                                          children: "technology marketplace",
                                                          href: Routes_Marketplace.colocation
                                                        }),
                                                    ". As a result, Datacenters.com has earned the trust of millions of data center and IT professionals across the globe."
                                                  ],
                                                  className: css.description
                                                })
                                          ],
                                          className: css.stroryDetails
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsxs(ReactSlick, {
                                                      dots: false,
                                                      infinite: true,
                                                      slidesToShow: 1,
                                                      slidesToScroll: 1,
                                                      rows: 1,
                                                      slidesPerRow: 1,
                                                      arrows: true,
                                                      children: [
                                                        JsxRuntime.jsx("img", {
                                                              src: aboutImage1
                                                            }),
                                                        JsxRuntime.jsx("img", {
                                                              src: aboutImage2
                                                            }),
                                                        JsxRuntime.jsx("img", {
                                                              src: aboutImage3
                                                            }),
                                                        JsxRuntime.jsx("img", {
                                                              src: aboutImage4
                                                            }),
                                                        JsxRuntime.jsx("img", {
                                                              src: aboutImage5
                                                            }),
                                                        JsxRuntime.jsx("img", {
                                                              src: aboutImage6
                                                            })
                                                      ]
                                                    }, "about-us-slider-client"),
                                                className: css.sliderContainer
                                              }),
                                          className: css.aboutImgHolder
                                        })
                                  ],
                                  className: Cx.cx([css.row])
                                }),
                            className: css.container
                          }),
                      className: css.aboutStory
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(AboutSectionHead.make, {
                                    title: "What We Stand For?",
                                    subtitle: "Learn more about our mission, vision and values."
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(IconBox.make, {
                                                        title: "Mission",
                                                        description: "Revolutionize the industry with innovative applications that disrupt the buying, selling. And management of information technology.",
                                                        icon: IconMission,
                                                        align: "Center"
                                                      }),
                                                  className: css.feature
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(IconBox.make, {
                                                        title: "Vision",
                                                        description: "The #1 marketplace for IT professionals based on number of provider agreement, data center listing, transaction volume and revenue.",
                                                        icon: IconVision,
                                                        align: "Center"
                                                      }),
                                                  className: css.feature
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(IconBox.make, {
                                                        title: "Value",
                                                        description: "High standards are a way of life. We are relentless in our pursuit of excellence in everything that we do.",
                                                        icon: IconValue,
                                                        align: "Center"
                                                      }),
                                                  className: css.feature
                                                })
                                          ],
                                          className: css.features
                                        }),
                                    className: css.row
                                  })
                            ],
                            className: css.container
                          }),
                      className: css.overview
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(AboutSectionHead.make, {
                                          title: "Join Us",
                                          subtitle: "Learn more about how you can get involved."
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("div", {
                                                        children: JsxRuntime.jsx(ProgramBox.make, {
                                                              title: "Provider Program",
                                                              description: "Sign-up to get your company, data centers, products, and services listed on our website and marketplace.",
                                                              icon: IconProvider,
                                                              link: "/users/sign_up#provider",
                                                              linkTitle: "Get Listed"
                                                            }),
                                                        className: css.feature
                                                      }),
                                                  JsxRuntime.jsx("div", {
                                                        children: JsxRuntime.jsx(ProgramBox.make, {
                                                              title: "Advertising",
                                                              description: "Learn about our advertising program and pricing for ads featured throughout the website.",
                                                              icon: IconAd,
                                                              link: Cloudinary.imageUrl("marketing/Datacenters.com-Ad-Rates-Brochure.pdf"),
                                                              linkTitle: "Download Media Kit"
                                                            }),
                                                        className: css.feature
                                                      })
                                                ],
                                                className: css.features
                                              }),
                                          className: css.row
                                        })
                                  ],
                                  className: css.container
                                }),
                            className: css.overview
                          }),
                      className: css.joinProgram
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(AboutSectionHead.make, {
                                          title: "Testimonials",
                                          subtitle: "Don't take our word for it. See what they're saying."
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Testimonial.make, {
                                                        name: "Vicki Patten",
                                                        role: "Rackspace",
                                                        description: "Rackspace helps its customers accelerate the value of cloud at every phase of their digital transformation journey and Datacenters.com is a natural partner for anyone on this journey.",
                                                        imageUrl: Cloudinary.imageUrl("f_auto/jtp0tlvnqmypqdn01mqv")
                                                      }),
                                                  JsxRuntime.jsx(Testimonial.make, {
                                                        name: "Chad Markle",
                                                        role: "Centersquare",
                                                        description: "Our company prides itself on being the secure infrastructure company with a global platform of 50+ best in class data centers.  We are happy to partner with Datacenters.com with its forward thinking, industry changing, global user experience being a great fit for our products. We are excited to be pioneers in the marketization of colocation and to be a part of the Datacenters.com Marketplace",
                                                        imageUrl: Cloudinary.imageUrl("f_auto/ia9cf2fwfj0jp3fbfwuu")
                                                      }),
                                                  JsxRuntime.jsx(Testimonial.make, {
                                                        name: "Tony Franchi",
                                                        role: "365 DataCenters",
                                                        description: "Creative solutions and pricing are all easily available in one place at Datacenters.com. It's a great partnership for us to help us connect with our clients. The marketplace makes it easy for everyone to do business.",
                                                        imageUrl: Cloudinary.imageUrl("f_auto/xda2sptoali9y3vcvtgj")
                                                      })
                                                ],
                                                className: css.list
                                              }),
                                          className: css.row
                                        })
                                  ],
                                  className: css.container
                                }),
                            className: css.overview
                          }),
                      className: css.testimonials
                    })
              ],
              className: css.aboutLayout
            });
}

var $$default = About$default;

export {
  css ,
  aboutImage1 ,
  aboutImage2 ,
  aboutImage3 ,
  aboutImage4 ,
  aboutImage5 ,
  aboutImage6 ,
  gcgImage ,
  gcfImage ,
  $$default as default,
}
/* css Not a pure module */
