// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconMission(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Mission";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsxs("g", {
                                      children: [
                                        JsxRuntime.jsx("circle", {
                                              id: "Oval",
                                              cx: "40",
                                              cy: "40",
                                              r: "37.5",
                                              stroke: "#7ACCC8",
                                              strokeWidth: "5"
                                            }),
                                        JsxRuntime.jsx("circle", {
                                              id: "Oval",
                                              cx: "40",
                                              cy: "40",
                                              r: "22.5",
                                              stroke: "#7ACCC8",
                                              strokeWidth: "5"
                                            }),
                                        JsxRuntime.jsx("circle", {
                                              id: "Oval",
                                              cx: "40",
                                              cy: "40",
                                              fill: "#099891",
                                              r: "10",
                                              stroke: Icon.mapColor(props.color)
                                            })
                                      ],
                                      id: "Mission",
                                      transform: "translate(280.000000, 2016.000000)"
                                    }),
                                id: "About-us",
                                fillRule: "nonzero",
                                transform: "translate(-280.000000, -2016.000000)"
                              }),
                          id: "Site",
                          fill: "none",
                          fillRule: "evenodd",
                          stroke: "none",
                          strokeWidth: "1"
                        }),
                    height: "100%",
                    width: "100%",
                    version: "1.1",
                    viewBox: "0 0 80 80",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconMission;

export {
  make ,
}
/* Icon Not a pure module */
