// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as AboutSectionHeadScss from "./AboutSectionHead.scss";

var css = AboutSectionHeadScss;

function AboutSectionHead(props) {
  var __subtitle = props.subtitle;
  var subtitle = __subtitle !== undefined ? __subtitle : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h2", {
                      children: props.title
                    }),
                subtitle !== "" ? JsxRuntime.jsx("div", {
                        children: subtitle,
                        className: css.subtitle
                      }) : null
              ],
              className: css.aboutSectionHeadContainer
            });
}

var make = AboutSectionHead;

export {
  css ,
  make ,
}
/* css Not a pure module */
