// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconVision(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Vision";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsx("g", {
                                children: JsxRuntime.jsxs("g", {
                                      children: [
                                        JsxRuntime.jsx("rect", {
                                              id: "Rectangle",
                                              height: "27",
                                              width: "21",
                                              rx: "3",
                                              stroke: "#8AAECB",
                                              strokeWidth: "3",
                                              x: "1.5",
                                              y: "51.5"
                                            }),
                                        JsxRuntime.jsx("rect", {
                                              id: "Rectangle",
                                              height: "80",
                                              width: "24",
                                              fill: "#22689F",
                                              rx: "3",
                                              x: "28",
                                              y: "0"
                                            }),
                                        JsxRuntime.jsx("polygon", {
                                              id: "Rectangle",
                                              fill: "#FFFFFF",
                                              points: "39 9 41 9 41 22 44 22 44 24 36 24 36 22 39 22 39 14 36 14",
                                              stroke: Icon.mapColor(props.color)
                                            }),
                                        JsxRuntime.jsx("rect", {
                                              id: "Rectangle",
                                              height: "52",
                                              width: "21",
                                              rx: "3",
                                              stroke: "#8AAECB",
                                              strokeWidth: "3",
                                              x: "57.5",
                                              y: "26.5"
                                            })
                                      ],
                                      id: "Vision",
                                      transform: "translate(680.000000, 2016.000000)"
                                    }),
                                id: "About-us",
                                fillRule: "nonzero",
                                transform: "translate(-680.000000, -2016.000000)"
                              }),
                          id: "Site",
                          fill: "none",
                          fillRule: "evenodd",
                          stroke: "none",
                          strokeWidth: "1"
                        }),
                    height: "100%",
                    width: "100%",
                    version: "1.1",
                    viewBox: "0 0 80 80",
                    xmlns: "http://www.w3.org/2000/svg"
                  })
            });
}

var make = IconVision;

export {
  make ,
}
/* Icon Not a pure module */
